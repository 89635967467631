.layout {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  .protected-content {
    flex: 1;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
  }
  
  .footer {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    padding: 10px;
    text-align: center;
    background: #F5F5F5;
    font-weight: 100;
    color: #9b9ca7;
  }
  
  .footer p {
    margin: 2px;
  }
  