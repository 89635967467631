@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Outfit:wght@100..900&display=swap');

html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  font-family: "Outfit", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  color: #333;
  -webkit-font-smoothing: antialiased;
  --color-background-dark: #ffffff;
  --color-primary: #FF7935; /* Naranja Oscuro */
  --color-secondary: #FF7935; /* Naranja Brillante */
  --color-white: #FFFFFF; /* Blanco */
  --color-button-bg: #23252F; 
  --color-highlight: #2ECC71; /* Verde vibrante para destacar botones */
  --color-terciary: #ffffff4a; /* Verde vibrante para destacar botones */
  background-color: #ffffff;
}

#root {
  width: 100%;
  min-height: 100%;
}

/* code {
  font-family: "Outfit", sans-serif;
  font-optical-sizing: auto;
  font-weight: 100;
  font-style: normal;
} */
