.layoutItem {
    margin-left: 250px;
    margin-top: 100px;
    margin-right: 10px;
}  

@media (width <= 768px) { 

    .layoutItem{
        margin: auto auto;
        margin-top: 200px;
        margin-left: 10px;
        margin-right: 10px;
    }

}