/* Estilo del contenedor principal */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 0 1.5rem;
}

.login {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px; /* Establece un ancho máximo común para formularios de login */
  background-color: #f9f9f9;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Estilo del título */
.login-container h2 {
  margin-bottom: 1.5rem;
  font-size: 1.75rem;
  text-align: center;
  color: #333;
}

/* Estilo del formulario */
.login-container form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

/* Estilo de las etiquetas */
.login-container label {
  margin-bottom: 0.5rem;
  font-size: 1rem;
  color: #555;
  text-align: left;
  width: 100%;
}

/* Estilo de los campos de entrada */
.login-container input {
  padding: 0.75rem;
  margin-bottom: 1.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.login-container input:focus {
  border-color: #007bff;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.25);
  outline: none;
}

/* Estilo del botón */
.login-container button {
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.login-container button:hover {
  background-color: #0056b3;
}

.login-container button:disabled {
  background-color: #aaa;
  cursor: not-allowed;
}

/* Estilo del mensaje de error */
.login-container p {
  color: red;
  font-size: 0.875rem;
  margin-top: 0.5rem;
  text-align: center;
}

.container-registrarse {
  margin: 10px;
}

/* Estilo responsivo para pantallas pequeñas */
@media (max-width: 600px) {
  .login-container {
    padding: 1rem;
  }

  .login-container h2 {
    font-size: 1.5rem;
  }

  .login-container input, 
  .login-container button {
    font-size: 0.875rem;
  }
}

