/* Modal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    color: #000000;
    margin-top: 20px;
    max-width: 600px;
    max-height: 600px;
    border-radius: 5px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F5F5F5;
    padding: 50px;
  }
  
  .close-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background: none;
    border: none;
    font-size: 1.0rem;
    cursor: pointer;
  }
  
  .modal-content img {
    width: 80%;
    height: 100%;
    object-fit: contain;
    padding: 20px;
  }

  