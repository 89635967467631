/* Navbar.css */

.navbar {
  display: flex;
  justify-content: center; /* Centrar los elementos horizontalmente */
  padding: 1em;
  position: fixed; /* Fijo en la parte superior */
  top: 0;
  left: 0;
  width: 100%; /* Ocupa todo el ancho de la pantalla */
  z-index: 1000; /* Asegúrate de que el navbar esté encima de otros elementos */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra ligera */
  background: #F5F5F5;
}

.navbar button {
  background: none;
  border: none;
  padding: 0.5em 1em;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  margin: 0 0.5em; /* Espacio entre botones */
  color: #142940;
  font-weight: 600;
}

.navbar button:hover {
  background-color: #6c757d; /* Fondo azul claro al pasar el mouse */
  color: white;
  border-radius: 5px;
}

.navbar button.active {
  background-color: #000000;
  color: #fff;
  border-radius: 5px;
  font-weight: 600;
}

/* Responsivo */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column; /* Cambia la dirección de los botones a columna */
    align-items: center;
    padding: 0.5em;
    background: #F5F5F5;
  }
}
