/* Sidebar.css */
.sidebar {
  width: 200px;
  height: 100vh;
  background: #F5F5F5;
  position: fixed;
  top: 0;
  left: -200px;
  transition: left 0.3s ease;
  z-index: 1030;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.sidebar.open {
  left: 0;
}

/* El sidebar siempre está abierto en vista de escritorio */
@media (min-width: 769px) {
  .sidebar {
    left: 0;
  }

  .container {
    margin-left: 200px; /* Ajusta según el ancho del sidebar */
  }

  .toggle-button {
    display: none;
    background: #F5F5F5;
  }
}

.toggle-button {
  position: absolute;
  top: 20px;
  left: 150px;
  width: 50px;
  height: 50px;
  background: #F5F5F5;
  border: none;
  cursor: pointer;
  z-index: 1050; /* Asegúrate de que el z-index sea lo suficientemente alto */
  transition: left 0.3s ease;
}

.toggle-button.closed {
  left: 0px;
}

.menu {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.menu a {
  color: #9b9ca7;
  font-weight: 500;
  text-decoration: none;
  margin: 10px 0;
  transition: color 0.3s ease;
}

.menu a:hover {
  color: #000000; /* Tomato color */
}